import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

import Digipro20 from "../assets/images/digipro20/CLARIVIZ DIGIPRO 2.0.webp";
import { Digipro20Data } from "../utils/ClarivizSeriesData";
import StoreImg from "../assets/images/store-locator.jpg";
import headerImg from "../assets/images/digipro20/headerImg.webp";

interface DigiproItem {
  imgSrc: string;
  altTxt: string;
  title: string;
  description: string;
}

interface ColumnMap {
  first: DigiproItem;
  second?: DigiproItem;
  third?: DigiproItem;
}

const ClarivizDigipro20 = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.DIGIPRO20);
  const twoColumnMap: ColumnMap[] = Digipro20Data.reduce((acc, item, index) => {
    if (index % 3 === 0) {
      acc.push({
        first: index === 0 ? item : Digipro20Data[index - 1] || undefined,
        second: index === 0 ? Digipro20Data[index + 1] || undefined : item,
        third: index === 0 ? undefined : Digipro20Data[index + 1] || undefined,
      });
    }
    return acc;
  }, [] as ColumnMap[]);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz DigiPro 2.0",
    description:
      "Clariviz DigiPro 2.0 lenses are designed for progressive users switching between digital devices and distant objects, offering smooth transitions and enhanced comfort.",
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Digital Device Friendly Progressive Lenses|Digital lenses|Clariviz digipro"
        description="Discover Clariviz Digipro 2.0 lenses, designed for progressive users who spend extended
                     periods on digital devices. Enjoy smoother transitions between near and intermediate views"
        keywords=" Digital user lenses Hyderabad, Computer lenses Hyderabad, Presbyopia correction lenses
                  Hyderabad, Best lenses for computer users Hyderabad, Blue light blocking lenses Hyderabad, Eye strain
                  relief lenses Hyderabad, Progressive lenses for digital device users Hyderabad, Computer glasses for
                  presbyopia Hyderabad, Digital device friendly lenses Hyderabad, Presbyopia lenses for computer users in
                  Hyderabad"
        name="Clariviz Digipro 2.0 Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz DigiPro 2.0 progressive lenses for digital device users"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Digipro 2.0
        </h1>
        <h4 className="font-medium text-lg md:text-xl text-primary py-2">
          The advanced progressive lens, tailored for your digital life.
        </h4>
        <p className="text-base text-black leading-8 w-full md:w-[98%] py-6">
          These lenses were developed to attend to the needs of progressive
          users who are looking for a good option and spend part of their days
          switching their focus from digital devices to objects on the distance
          zone. Developed especially for digital life, Clariviz Digipro 2.0
          provides a smoother and quicker transition between the near and
          intermediate view.
        </p>

        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={Digipro20} alt="Clariviz DigiPro 2.0 lenses for smooth transitions and digital comfort" />
        </div>
        {/* 3 Column */}
        <div className="pt-4 pb-12 w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                Progressive users who have a high usage of digital devices for
                long hours and are looking for quality and comfort
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                Good vision and comfort during the usage of digital devices
              </li>
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                Smooth transition between the near and intermediate zone
              </li>
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                Good and wide near and distance vision
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">
                MFH (Minimum Fitting Height)
              </p>
              <ul className="w-[90%] flex flex-col gap-4">
                <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                  14 mm | 16 mm | 18 mm
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">Personalization</p>
              <ul>
                <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                  Fully Personalized Progressive Lens
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Technologies */}
        <div>
          <h1 className="text-3xl text-primary font-bold">Technologies</h1>

          <div className="hidden md:pt-8 md:flex md:flex-col">
            {twoColumnMap.map((row, index) => (
              <div
                key={index}
                className={`grid grid-cols-3 ${
                  index % 2 !== 0 ? "bg-slate-400/10" : ""
                }`}
              >
                {[row.first, row.second, row.third].map(
                  (item, colIndex) =>
                    item && (
                      <div
                        key={colIndex}
                        className={`${
                          index === 0
                            ? colIndex === 0
                              ? "border"
                              : "border-r border-t border-b"
                            : colIndex === 0
                            ? "border-b border-l border-r"
                            : "border-r border-b border-t"
                        } p-[0.85rem]`}
                      >
                        <div className="flex items-center gap-x-4">
                          <div className="w-[24%] h-[24%]">
                            <img
                              src={item.imgSrc}
                              alt={item.altTxt}
                              className="w-full h-full"
                            />
                          </div>
                          <div className="font-bold text-primary text-base">
                            {item.title}
                          </div>
                        </div>
                        <p className="pt-4 tracking-wide">{item.description}</p>
                      </div>
                    )
                )}
              </div>
            ))}
          </div>
          <div className="py-8 md:hidden">
            {Digipro20Data.map((item, index) => (
              <div
                key={index}
                className={`grid grid-cols-1 ${
                  index % 2 !== 0 && "bg-slate-400/10"
                }
                `}
              >
                <div
                  className={`${
                    index % 2 === 0 ? "border" : "border-l border-r"
                  } p-[0.85rem]`}
                >
                  <div className="flex items-center gap-x-4">
                    <div className="w-[24%] h-[24%]">
                      <img
                        src={item.imgSrc}
                        alt={item.altTxt}
                        className="w-full h-full"
                      />
                    </div>
                    <div className="font-bold text-primary text-base">
                      {item.title}
                    </div>
                  </div>
                  <p className="pt-4 tracking-wide">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizDigipro20;
