import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

import StoreImg from "../assets/images/store-locator.jpg";
import ZSeries10 from "../assets/images/zseries1.0/CLARIVIZ Z SERIES 1.0.webp";
import { ZSeries10Data } from "../utils/ClarivizSeriesData";
import headerImg from "../assets/images/zseries1.0/headerImg.webp";

const ClarivizZSeries10 = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.ZSERIES10);
  const threeColumnMap = ZSeries10Data.reduce((acc, item, index) => {
    if (index % 3 === 0) {
      acc.push([item]);
    } else {
      acc[acc.length - 1].push(item);
    }
    return acc;
  }, [] as Array<typeof ZSeries10Data>).map(([first, second, third]) => ({
    first,
    second,
    third,
  }));
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz Z Series 1.0 Lenses",
    description:
      "Clariviz Z Series 1.0 lenses are optimized for natural vision and provide balanced visual areas, making them ideal for progressive users who need comfort and clarity.",
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Personalized Progressive Lenses in Hyderabad|Eye lens Hyderabad|Clariviz"
        description="Experience naturally good vision with Clariviz Z Series 1.0 lenses, optimized for your unique
                     accommodative ability. Wide visual fields, reduced swim effect, and precise vision."
        keywords="Personalized progressive lenses Hyderabad, Spatial Vision technology Hyderabad,
                  Accommodative ability lenses Hyderabad, wide visual fields lenses Hyderabad, Reduced swim effect
                  lenses Hyderabad, Precise vision lenses Hyderabad, Eye Focus Profiling technology Hyderabad, Ray
                  Tracing technology Hyderabad, , Progressive lenses for presbyopia Hyderabad, High-quality progressive
                  lenses Hyderabad, Comfortable progressive lenses Hyderabad, Thinner progressive lenses Hyderabad"
        name="Clariviz Z Series 1.0"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz Z Series 1.0 progressive lenses header"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>

      <div className="py-8 flex flex-col px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Z Series 1.0
        </h1>
        <h4 className="font-medium text-lg md:text-xl text-primary py-2">
          Optimised to ensure the best natural vision for you.
        </h4>
        <p className="text-base text-black leading-8 w-full md:w-[98%] py-6">
          Clariviz Z Series 1.0 lenses use{" "}
          <span className="text-primary italic font-medium">
            <q>accommodation</q>
          </span>{" "}
          to improve your spatial vision in a super dynamic way. The
          optimization process also takes into account your accommodative
          ability to focus, to offer naturally good vision, even when you're on
          the move.
        </p>
        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={ZSeries10} alt="Clariviz Z Series 1.0 optimized lenses" />
        </div>
        {/* 3 Column */}
        <div className="pt-4 pb-12 w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Experienced progressive users looking for high quality lenses
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                New progressive users searching for an easy to adapt option
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Users who have a demand for wider visual fields
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Available for all type of prescriptions and addition powers
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Wide and balanced visual areas
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Good visual quality for all the zones
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Reduced swim effect and peripheral blur
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Easy to adapt
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Highly comfortable, even during digital device usage
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Thinner lenses
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">
                MFH (Minimum Fitting Height)
              </p>
              <ul className="w-full flex flex-col gap-4">
                <li className="list-disc text-base text-black w-full">
                  14 mm | 15 mm | 16 mm | 17 mm | 18 mm
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">Personalization</p>
              <ul>
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  Fully Personalized Progressive Lens
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Technologies */}
        <div>
          <h1 className="text-3xl text-primary font-bold">Technologies</h1>
          {/* Technologies with reduce functions */}
          <div className="hidden md:pt-8 md:flex md:flex-col">
            {threeColumnMap.map((row, index) => (
              <div
                key={index}
                className={`grid grid-cols-3 ${
                  index % 2 !== 0 ? "bg-slate-400/10" : ""
                }`}
              >
                {[row.first, row.second, row.third].map(
                  (item, colIndex) =>
                    item && (
                      <div
                        key={colIndex}
                        className={`${
                          index === 0
                            ? colIndex === 0
                              ? "border"
                              : "border-r border-t border-b"
                            : colIndex === 0
                            ? "border-b border-l border-r"
                            : "border-r border-b"
                        } p-[0.85rem]`}
                      >
                        <div className="flex items-center gap-x-4">
                          <div className="w-[24%] h-[24%]">
                            <img
                              src={item.imgSrc}
                              alt={item.altTxt}
                              className="w-full h-full"
                            />
                          </div>
                          <div className="font-bold text-primary text-base">
                            {item.title}
                          </div>
                        </div>
                        <p className="pt-4 tracking-wide">{item.description}</p>
                      </div>
                    )
                )}
              </div>
            ))}
          </div>
          {/* Mobile screens */}
          <div className="py-8 md:hidden">
            {ZSeries10Data.map((item, index) => (
              <div
                key={index}
                className={`grid grid-cols-1 ${
                  index % 2 !== 0 && "bg-slate-400/10"
                }
                `}
              >
                <div
                  className={`${
                    index % 2 === 0 ? "border" : "border-l border-r"
                  } p-[0.85rem]`}
                >
                  <div className="flex items-center gap-x-4">
                    <div className="w-[24%] h-[24%]">
                      <img
                        src={item.imgSrc}
                        alt={item.altTxt}
                        className="w-full h-full"
                      />
                    </div>
                    <div className="font-bold text-primary text-base">
                      {item.title}
                    </div>
                  </div>
                  <p className="pt-4 tracking-wide">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizZSeries10;
