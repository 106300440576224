import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

import BecomePartner from "../assets/images/BecomePartner.jpeg";
import Careers from "../assets/images/Careers.jpg";
import HeaderImg from "../assets/images/header.webp";
import LensCatalogImg from "../assets/images/lens-catalog.jpg";
import React from "react";
import StoreImg from "../assets/images/store-locator.jpg";
import ZdigiPro2 from "../assets/images/digipro2-0.jpg";
import Zseries1 from "../assets/images/Zseries1-0.jpg";
import Zseries2 from "../assets/images/Zseries2-0.jpg";
import ZseriesDS from "../assets/images/zseriesds.jpg";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.HOME);
  const navigate = useNavigate();
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz Lenses | Lens Solutions",
    description:
      "Welcome to Clariviz Lenses – state-of-the-art optical solutions for all your visual needs. Experience unparalleled precision, aesthetics, and value.",
    url: UrlLink
  });
  return (
    <div className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Eye lenses in Hyderabad|Best lenses and eye wear|Clariviz"
        description="Discover Clariviz Lenses, the ultimate destination for premium eye lenses in Hyderabad. Our state-of-the-art lenses offer unparalleled precision, reliability, and clarity"
        keywords="Best Progressive Lenses for Presbyopia in Hyderabad, Advanced Digital Eye Lenses in
                  Hyderabad, Precision Eyewear Solutions in Hyderabad, Best contact Lenses in Hyderabad, Best Eye
                  Lenses for Active Lifestyle in Hyderabad, best eye lenses Hyderabad, Hyderabad eye lens manufacturer,
                  eye lens dealer Hyderabad, progressive lens Hyderabad, eyewear Hyderabad, best quality eye lens
                  Hyderabad, eye glass lens in Hyderabad"
        name="Clariviz"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[35vh] xs:h-[40vh] small:h-[45vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-10"></div>
        <div className="absolute inset-0 flex items-center justify-start py-1 px-2 small:px-custom-padding">
          <div className="w-2/3 flex flex-col gap-1">
            <h2 className="text-balance text-white font-bold text-[1.05rem] xs:text-xl small:text-2xl md:text-3xl mlg:text-4xl">
              <div className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                SAY “HELLO” TO
              </div>
              <div className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                CLARIVIZ LENSES
              </div>
            </h2>
            <h1
              className="text-balance text-white 
            text-tinybase xs:text-base small:text-md md:text-2xl
            drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] leading-snug 
            w-[83%] xs:w-[85%] small:w-[88%]
            font-light text-border"
            >
              Get superior quality, unparalleled precision, incomparable
              aesthetics, and unmatched value!
            </h1>
          </div>
        </div>
      </div>
      <div className="py-8 flex flex-col space-y-6 px-custom-padding">
        <p className="text-base text-black leading-loose md:leading-8 w-[98%]">
          Welcome to the world of Clariviz Lenses – state-of-the-art optical
          solutions for literally all of your visual needs. Through the most
          stringent processes and procedures, our lenses are crafted with
          professional pride, to offer you the visual acuity you require and
          deserve.
        </p>
        <p className="text-base text-black leading-loose md:leading-8 w-[98%]">
          Your eyewear lenses should ideally offer you clear and highly accurate
          vision, allowing you to see the world around with ease and confidence.
          With the very latest advancements in lens technology being employed at
          Clariviz, you can now experience unparalleled precision, reliability,
          and clarity like never before. Designed with precision engineering and
          manufactured with international-quality materials on world-class
          equipment, our lenses offer unbeatable accuracy, providing the perfect
          solution to all your vision correction needs.
        </p>
        <p className="text-base text-black leading-loose md:leading-8 w-[95%]">
          Clariviz lenses deliver completely reliable performance, ensuring that
          your vision remains crystal clear no matter the circumstances. So
          welcome to our world. See better, and enhance the quality of your
          life, with gold-standard lenses by Clariviz!
        </p>
      </div>
      <div className="px-custom-padding py-4">
        <button
          className="btn btn-secondary"
          onClick={(e: any) => {
            navigate(NavStrings.CLARIVIZLENSES);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Find out more
        </button>
      </div>
      <ImageTextLayout
        imageSrc={ZseriesDS}
        subtitle="Clariviz Z Series DS"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clariviz Z Series DS lenses adapt to the demands of modern life. The technology meets the challenging visual needs of the most active presbyopes, who require clear and stable vision, even when they’re engaged in highly dynamic activities.."
        buttonTxt="Find out more"
      />
      <ImageTextLayout
        imageSrc={Zseries2}
        subtitle="Clariviz Z Series 2.0"
        title="Ground-breaking technology to ensure impeccable visual quality for you."
        description="Clariviz Z Series 2.0 lenses revolutionize the current concept of 
        personalization in a more sophisticated and effective way because the optimization 
        process also considers the wearer’s accommodative ability to focus, at different distances."
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={Zseries1}
        subtitle="Clariviz Z Series 1.0"
        title="Optimized for you to ensure the best natural vision for you."
        description="Clariviz Z Series 1.0 lenses use ‘accommodation’ to improve your spatial 
        vision in a super dynamic way. The optimization process also takes into account your 
        accommodative ability to focus, to offer naturally good vision, even when you’re on the move."
        buttonTxt="Find out more"
      />
      {/* Video Container */}
      {/* <div className="max-w-screen-2xl mx-auto p-custom-padding">
        <div
          className="flex flex-col md:flex-row-reverse items-start bg-[#CCCCCC] rounded-sm p-[2.45rem] 
      "
        >
          <div className="w-full flex justify-center items-center rounded-sm bg-[#d3d3d3] h-[320px] md:w-1/3">
            Video Container
          </div>
          <div className="w-full md:w-2/3 flex flex-col">
            <h3 className="text-xl md:text-45pt font-light text-black md:leading-[2.55rem]">
              {" "}
              Clariviz Lens Manufacturing:
            </h3>
            <h2 className="text-2xl leading-tight md:text-45pt font-bold text-black md:leading-[2.55rem]">
              How we do it
            </h2>
          </div>
        </div>
      </div> */}
      <ImageTextLayout
        imageSrc={ZdigiPro2}
        subtitle="Clariviz Digipro 2.0"
        title="The advanced progressive lens, tailored for your digital life."
        description="CLARIVIZ DIGIPRO 2.0 lenses help you adapt to the requirements of 
        modern life. Their technology meets the visual needs of the most active presbyopes, with wide 
        near and intermediate visual fields, giving you an agile focus when using electronic devices."
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show me now"
      />
      <ImageTextLayout
        imageSrc={Careers}
        title="Careers at Clariviz"
        description="We're a dynamic and innovative eyewear company which values its 
        employees and is committed to creating a positive, inclusive work environment. 
        If you're passionate about eyewear and looking for an exciting and 
        upwardly-mobile career opportunity, then you're welcome to apply!"
        buttonTxt="Send your CV"
        reverse
      />
      <ImageTextLayout
        imageSrc={BecomePartner}
        title="Become a Clariviz Partner"
        description="As a leading manufacturer of spectacle lenses, we provide unparalleled quality, innovation, 
        and support to our business partners. By collaborating with us, you gain access to our extensive range of 
        premium lenses, cutting-edge technology, and expert insights, enabling you to meet the diverse needs of your customers."
        description2="Experience the advantages of a partnership with us and grow your business with confidence. Become a business partner today and join us in achieving excellence."
        buttonTxt="Become a Clariviz Partner"
      />
      <ImageTextLayout
        imageSrc={LensCatalogImg}
        title="Latest Clariviz Lens Catalog"
        description="Explore the latest innovations in lens technology with our newest catalog! 
        As a leading manufacturer in the industry, we are dedicated to providing top-quality lenses 
        that meet the diverse needs of our customers. Our catalog features an extensive range of products, 
        from cutting-edge optical solutions to the latest advancements in lens design."
        showInputs={true}
        buttonTxt="Submit to Download"
        reverse
      />
    </div>
  );
};

export default Home;
